import React, {useState} from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import BlockContent from '../components/block-content'
import PageHeader from "../components/page-header"
import Container from '../components/container'
import SEO from '../components/seo'

import Layout from '../containers/layout'

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

export const query = graphql`
  query MissionPageQuery {
    page: sanityMission(_id: { regex: "/(drafts.|)mission/" }) {
      id
      title
      image {
        asset {
          _id
        }
      }
      _rawBody
      values {
        title
        _rawDescription
        image {
          asset {
            _id
          }
        }
      }
    }
  }
`

const ParagraphClass = 'inline-block relative w-full text-xs px-8 md:text-sm'
const HeadingClass = 'inline-block relative w-full text-xs md:text-3xl my-8'

const MissionPage = props => {

  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.page

  if (!page) {
    throw new Error(
      'Missing "Mission" page data. Open the studio at http://localhost:3333 and add "Mission" page data and restart the development server.'
    )
  }


  const Value = ({ value, last, index }) => {

    const [active, setActive] = useState(false)

    const OpenContent = () => {
      if (active === true) {
        setActive(false)
      } else {
        setActive(true)
      }

    }
    
    return ( <div className={`inline-block relative w-full mb-6 max-w-3xl rounded-md`}>
      <div onClick={() => OpenContent()} className="flex justify-between items-top">
       
        <div className="inline-block relative w-full">
          <div className="flex pb-2 justify-between items-end lg:items-center relative w-full accordian-title mb-2">
          <strong  className={`text-lg max-w-xs lg:max-w-lg font-bolder text-white ${active === true ? 'orange' : 'text-white'} `}>
            {value.title}
          </strong>
            <img className={`w-4 h-4 mb-2 accordianX ${active === true ? 'show' : ''}`} src="/accordianStatus.svg"></img>

          </div>
          {value._rawDescription && (
            <div className={`${ParagraphClass} list text-white accordian-content ${active === true ? 'show' : 'closed'}`}>
              <BlockContent blocks={value._rawDescription} />
            </div>
          )}
        </div>
      </div>
    </div>
  )}

  return (
    <Layout>
      <SEO title={page.title} />
      <Container>
        <div className="inline-block relative w-full py-16 lg:py-32 text-left dark-green md:text-center hill-background">
          <div className="inline-block relative w-full">
            <div className="lg:mx-10 lg:mx-56">

            <PageHeader
              title={page.title || `Mission & values`}
              // image={page.image ? page.image : undefined}
              body={page._rawBody ? page._rawBody : undefined}
              pageClass={"text-xs"}
            />
            </div>
            <div className="inline-block relative w-full">
              {page.values && page.values.length > 0 ? (
                <div className="flex flex-col items-center relative w-full">
                 <h1 className=" max-w-xl relative px-2 border-b-4 mb-16 text-center text-4xl md:text-5xl font-semibold">Values</h1>
                  <div className="inline-block relative px-10 md:px-none w-full text-white text-left max-w-2xl">
                    {page.values.map((value, index) => (
                      <Value
                        value={value}
                        last={index === page.values.length - 1}
                        key={`value-${index}`}
                        index={index}
                      />
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default MissionPage
